// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")

require('popper.js');
$ = require('jquery');
window.$ = window.jQuery = $;
require('bootstrap');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

require('../../assets/stylesheets/stylesheets.js');

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", function() {
    $(document).ready(function (e) {
        document.getElementById("contact-form-submit").addEventListener("click", function (event) {
            event.preventDefault();
            document.getElementById('contact-form').submit()
        });

        document.getElementById("contact-form-submit-key").addEventListener("keydown", function (event) {
            event.preventDefault();

        });

        document.getElementById("1stfloor").addEventListener("click", function (event) {
            const firstFloor = document.getElementById('1stfloor_blueprint');
            const secondFloor = document.getElementById('2ndfloor_blueprint');
            const ffButton = document.getElementById('1stfloor');
            const sfButton = document.getElementById('2ndfloor');
            firstFloor.classList.remove('hidden-blueprint');
            secondFloor.classList.add('hidden-blueprint');
            ffButton.classList.add('selected');
            sfButton.classList.remove('selected');
        });


        document.getElementById("2ndfloor").addEventListener("click", function (event) {
            const firstFloor = document.getElementById('1stfloor_blueprint');
            const secondFloor = document.getElementById('2ndfloor_blueprint');
            const ffButton = document.getElementById('1stfloor');
            const sfButton = document.getElementById('2ndfloor');
            firstFloor.classList.add('hidden-blueprint');
            secondFloor.classList.remove('hidden-blueprint');
            ffButton.classList.remove('selected');
            sfButton.classList.add('selected');

        });

    })
})

$(document).on('turbolinks:load', function () {
$(document).ready(function() {
    var cookie = false;
    var cookieContent = $('.cookie-disclaimer');

    checkCookie();

    if (cookie === true) {
        cookieContent.hide();
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    function checkCookie() {
        var check = getCookie("acookie");
        if (check !== "") {
            return cookie = true;
        } else {
            return cookie = false; //setCookie("acookie", "accepted", 365);
        }

    }
    $('.accept-cookie').click(function () {
        setCookie("acookie", "accepted", 365);
        cookieContent.hide(500);
    });
});
});
